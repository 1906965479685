import { AxiosRequestConfig,  } from 'axios';
import api from "../interceptor/interceptor.ts"

async function Http_delete<T>(API: string, config?: AxiosRequestConfig): Promise<T> {
    return await api.delete(API, config);
} 
async function Http_get<T>(API: string, config?: AxiosRequestConfig): Promise<T> {
    return await api.get(API, config);
}
async function Http_post<T>(API: string, body?: any, config?: AxiosRequestConfig): Promise<T> {
    return await api.post(API, body, config);
}
async function Http_put<T>(API: string, body?: any, config?: AxiosRequestConfig): Promise<T> {
    return await api.put(API, body, config);
}


export default { Http_delete, Http_get, Http_post, Http_put } 