import React, { useState, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Layout, Spin, ConfigProvider  } from 'antd';
import { ThemeProvider } from 'styled-components';

import LogoffButton from './components/LogoffButton';
import SidebarMenu from './components/SidebarMenu';
import ProtectedRoute from './components/ProtectedRoute';
import { AuthProvider } from './components/AuthProvider';

const { Header, Content } = Layout;

const Home = lazy(() => import('./components/Home'));
const Admin = lazy(() => import('./components/Admin'));
const GerenciarUsuarios = lazy(() => import('./components/Master/Usuarios/GerenciarUsuarios'));
const Mechanic = lazy(() => import('./components/Mechanic'));
const LavaCar = lazy(() => import('./components/LavaCar/Dashboard'));
const Login = lazy(() => import('./components/Login'));
const Profile = lazy(() => import('./components/Perfil/EditProfile'));
const ForbiddenPage = lazy(() => import('./components/ForbiddenPage'));
const CheckList = lazy(() => import('./components/Conferencia/CheckList'));
const CheckListItens = lazy(() => import('./components/Conferencia/CheckListItens'));

function App() {
  const [collapsed, setCollapsed] = useState(false);
  const userRole = localStorage.getItem('role');

  const darkTheme = {
    token: {
      colorPrimary: '#1890ff',
      menuDarkBg: '#004AAD',
      menuDarkSubmenuBg: '#004AAD',
      menudarkSubMenuItemBg: '#FFFFFF',
      menuDarkColor: 'rgba(255, 255, 255, 0.85)',
      menuDarkHighlightColor: '#ffffff',
      menuDarkItemActiveBg: '#4c4c4c',
      menuDarkSelectedItemIconColor: '#ffffff',
      menuDarkSelectedItemTextColor: '#ffffff',
      
    },
  };
  

  return (
    <ConfigProvider theme={darkTheme}>
      <ThemeProvider theme={darkTheme}>
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={
            <Suspense fallback={<Spin size="large" />}>
              <Login />
            </Suspense>
          } />
          <Route path="/" element={
            <Suspense fallback={<Spin size="large" />}>
              <Login />
            </Suspense>
          } />
          <Route path="*" element={
            <Layout style={{ minHeight: '100vh' }}>
              <SidebarMenu collapsed={collapsed} setCollapsed={setCollapsed} userRole={userRole} />
              <Layout>
                <Header style={{ color: 'white', fontSize: '1.5em', display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#004AAD' }}>
                  <div style={{ flex: 1 }}></div>
                  <LogoffButton />
                </Header>
                <Content style={{ margin: '24px 16px', padding: '20px', background: '#fff', overflow: 'auto' }}>
                  <Suspense fallback={<Spin size="large" />}>
                    <Routes>
                      <Route path="/admin" element={<ProtectedRoute component={Admin} />} />
                      <Route path="/master/usuarios/gerenciarUsuarios" element={<ProtectedRoute component={GerenciarUsuarios} />} />
                      <Route path="/mechanic/maintenance" element={<ProtectedRoute component={Mechanic} />} />
                      <Route path="/lavacar" element={<ProtectedRoute component={LavaCar} />} />
                      <Route path="/conferencia/checklist" element={<ProtectedRoute component={CheckList} />} />
                      <Route path="/conferencia/itens" element={<ProtectedRoute component={CheckListItens} />} />
                      <Route path="/home" element={<ProtectedRoute component={Home} />} />
                      <Route path="/perfil/editprofile" element={<ProtectedRoute component={Profile} />} />
                      <Route path="/403" element={<ProtectedRoute component={ForbiddenPage} />} />
                    </Routes>
                  </Suspense>
                </Content>
              </Layout>
            </Layout>
          } />
        </Routes>
      </Router>
    </AuthProvider>
    </ThemeProvider>
    </ConfigProvider>
  );
}

export default App;
