import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';  // Certifique-se de que o caminho para o import está correto
import { Menu, Dropdown, Avatar } from 'antd';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';

function LogoffDropdown() {
  const navigate = useNavigate();
  const { logout } = useAuth();  // Uso do hook useAuth para acessar o logout

  const handleLogoff = () => {
    logout();  // Chama o método logout do contexto de autenticação
    navigate('/login', { replace: true });  // Redireciona para a tela de login
  };

  const menu = (
    <Menu>
      {/* <Menu.Item key="profile" icon={<UserOutlined />}>
        Perfil
      </Menu.Item> */}
      <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={handleLogoff}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Avatar style={{ cursor: 'pointer' }} icon={<UserOutlined />} />
    </Dropdown>
  );
}

export default LogoffDropdown;
