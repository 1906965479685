import { config } from "dotenv";
import service from "../httpService/httpService.ts";

//endPoint
//getUserPermitions
//getCheckListItensEmpresas
//addCheckListRegistro

async function getToken(endPoint: string) {
    const credentials = { username: process.env.REACT_APP_USER, password: process.env.REACT_APP_PASS }
    return service.Http_post(`${process.env.REACT_APP_API_URL}/${endPoint}/auth`, credentials)
        .then((data: any) => data?.data?.token || null)
        .catch(error => error);
}

const getUSerPermissions = async (params: any) => {
    const token = await getToken("getUserPermitions")
    const config = {
        params: { params },
        headers: { Authorization: `Bearer ${token}` }
    }
    return service.Http_get<Array<any>>(`${process.env.REACT_APP_API_URL}/getUserPermitions`, config).then((data: any) => data?.data)
}



export default {  getUSerPermissions,getToken }