import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Layout, Menu } from 'antd';
import {
  ToolOutlined,
  CarOutlined,
  DashboardOutlined,
  SolutionOutlined,
  TransactionOutlined,
  UnlockOutlined,
  UserSwitchOutlined
} from '@ant-design/icons';
import { useAuth } from './AuthProvider';
import logo from '../assets/logo.png'
import axios from 'axios';

const { Sider } = Layout;
const { SubMenu, Item } = Menu;

function SidebarMenu({ collapsed, setCollapsed, userRole }) {
  const { user } = useAuth();
  const [role, setRole] = useState([]);

  useEffect(() => {
    const fetchRole = async () => {
      const authUrlUserPermissions = `${process.env.REACT_APP_API_URL}/getUserPermitions/auth`;
      const credentials = { username: process.env.REACT_APP_USER, password: process.env.REACT_APP_PASS };
      try {
        const getTokenUserPermissions = await axios.post(authUrlUserPermissions, credentials);
        const tokenUserPermissions = getTokenUserPermissions.data.token;
        const params = { user: user[0].Id };
        const responseUserPermission = await axios.get(`${process.env.REACT_APP_API_URL}/getUserPermitions`, {
          params: { params },
          headers: { Authorization: `Bearer ${tokenUserPermissions}` }
        });
       
        setRole(responseUserPermission.data);
      } catch (error) {
        console.error('Falha ao buscar dados da API', error);
      }
    };

    fetchRole();
  }, [user]);

  //const hasUserRole = role.some(role => role.role === 'user');
  const hasAdminRole = role.some(role => role.role === 'admin');
  const hasMechanicRole = role.some(role => role.role === 'mechanic');
  const hasWasherRole = role.some(role => role.role === 'washer');
  const hasConferenceRole = role.some(role => role.role === 'conference');
  const hasFinanceRole = role.some(role => role.role === 'finance');
  const hasMasterRole = role.some(role => role.role === 'master');
  const StyledMenu = styled(Menu)`
  &.ant-menu-dark,
  &.ant-menu-dark > .ant-menu {
    color: rgba(255, 255, 255, 0.85); /* Cor customizada */
    background: #1f1f1f; /* Background customizado */
  }
`;
  
  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={setCollapsed}
      breakpoint="lg"
      collapsedWidth="80" // Ajuste para permitir visualização da logo mesmo quando colapsado
      trigger={null}
      style={{ overflow: 'hidden', backgroundColor: '#004AAD' }}
    >
      <div style={{ padding: '24px', textAlign: 'center' }}>
        <img
          src={logo}// Substitua pelo caminho correto da sua imagem
          alt="Logo"
          style={{
            width: collapsed ? '48px' : '80%', // Ajuste de tamanho conforme o estado de colapso
            height: 'auto',
            borderRadius: '50%', // Torna a imagem redonda
            transition: 'width 0.3s ease-in-out' // Suaviza a transição de tamanho
          }}
        />
      </div>
      <Menu  theme='dark' mode="inline" style={{backgroundColor: '#004AAD', fontWeight: 'bold', color: '#FFFFFF'}} defaultSelectedKeys={['1']} onClick={() => setCollapsed(window.innerWidth < 768)}>
        <Item key="1" icon={<DashboardOutlined />}>
          <Link to="/home">Dashboard</Link>
        </Item>
        {(hasConferenceRole || hasMasterRole ) && (
        <StyledMenu.SubMenu key="sub1" icon={<SolutionOutlined />} title="Conferência" style={{backgroundColor: '#004AAD' }}>
          <Item key="2" >
            <Link to="/conferencia/checklist">Check List</Link>
          </Item>
          {hasMasterRole && (
          <Item key="14">
            <Link to="/conferencia/itens">Itens</Link>
          </Item>
          )}
        </StyledMenu.SubMenu>
        )}
        {(hasMechanicRole || hasMasterRole) && (
          <SubMenu key="sub2" icon={<ToolOutlined />} title="Auto Center">
            <Item key="3">
              <Link to="/mechanic/maintenance">Tarefas de Manutenção</Link>
            </Item>
            <Item key="4">
              <Link to="/mechanic/parts">Estoque de Peças</Link>
            </Item>
          </SubMenu>
        )}
        {(hasWasherRole || hasMasterRole) && (
          <SubMenu key="sub3" icon={<CarOutlined />} title="Lava Car">
            <Item key="5">
              <Link to="/lavacar">Serviços</Link>
            </Item>
            <Item key="6">
              <Link to="/washer/supplies">Suprimentos de Limpeza</Link>
            </Item>
          </SubMenu>
        )}
      {(hasFinanceRole || hasMasterRole ) && (
        <SubMenu key="sub7" icon={<TransactionOutlined />} title="Financeiro">
          <Item key="11">
            <Link to="/conferencia/checklist">Nota Fical</Link>
          </Item>
          <Item key="12">
            <Link to="/conferencia/checklist">NFS-e</Link>
          </Item>
          <Item key="13">
            <Link to="/conferencia/checklist">NFC-e</Link>
          </Item>
        </SubMenu>
        )}
        {(hasAdminRole || hasMasterRole) && (
          <SubMenu key="sub5" icon={<UserSwitchOutlined />} title="Administrador">
            <Item key="7">
              <Link to="/admin/manage">Usuários</Link>
            </Item>
            <Item key="8">
              <Link to="/admin/settings">Configurações do Sistema</Link>
            </Item>
            <Item key="8">
              <Link to="/admin/settings">Certificados</Link>
            </Item>
          </SubMenu>
        )}
      {hasMasterRole && (
          <SubMenu key="sub6" icon={<UnlockOutlined />} title="Master">
            <Item key="9">
              <Link to="/admin/manage">Empresas</Link>
            </Item>
            <Item key="10">
              <Link to="master/usuarios/gerenciarUsuarios">Usuários</Link>
            </Item>
          </SubMenu>
        )}
      </Menu >
    </Sider>
  );
}

export default SidebarMenu;
