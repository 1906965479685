// src/index.js

import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


const container = document.getElementById('root');
const root = createRoot(container); // Cria uma raiz de renderização com createRoot.


if (!localStorage.getItem('users')) {
  const users = [
    { username: 'admin', password: 'admin123', role: 'admin' },
    { username: 'mecanico', password: 'mec123', role: 'mechanic' },
    { username: 'lavador', password: 'lav123', role:  'washer' },
    { username: 'usuario', password: 'user123', role: 'user' }
  ];

  localStorage.setItem('users', JSON.stringify(users));
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
