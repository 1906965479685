import axios, { AxiosResponse } from 'axios';
import permissionService from '../permission/permissionService';


// const getToken = localStorage.getItem('token');
// //fazer o decrypt no back
// const token = cryptoFunctions.decryptToken(getToken)

// //alterar o tempo de validade do token 
// // fazer log off caso token invalido

// if (token)
//     config.headers['Authorization'] = `Bearer ${token}`;


//fazer validacoes de permissao na api?


axios.interceptors.request.use(
    async config => {
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);


axios.interceptors.response.use(
    response => {
        return (response as AxiosResponse)
    },
    error => {
        return Promise.reject(error);
    }
);

export default axios;
